/*
 * @Date: 2023-02-12 13:09:32
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-13 10:19:57
 * @FilePath: \J1-M1-QRP-CODE\config\Fatch.config.js
 */
import Error from './Fatch-Error.js'
import HostConfig from '$CONFIG/Host.config'
import Router from 'next/router';
export const Apiversion = '?api-version=1.0&brand=JBO&Platform=Desktop';

// 登录|超时 清除掉登录信息

function RemoveItemStorage(needToLogin){
	localStorage.removeItem('memberToken');
	localStorage.removeItem('memberInfo');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('username');
	sessionStorage.removeItem('loginStatus');
}

export const getPlatform = () => {
	if (navigator.userAgent.match(/webOS/i)
		|| navigator.userAgent.match(/iPhone/i)
		|| navigator.userAgent.match(/iPod/i)
		|| navigator.userAgent.match(/iPad/i)) {
		return 'IOS';
	} else if (navigator.userAgent.match(/Android/i)
		|| navigator.userAgent.match(/BlackBerry/i)
		|| navigator.userAgent.match(/Windows Phone/i)){
		return 'Android';
	} else {
		return 'Desktop';
	}
}

/**
 * @param { Function } fetch_promise
 * @param { number | any } timeout { 超时时间 }
 */
export function timeout_fetch(fetch_promise, timeout = 120000) {
	let timeout_fn = null;
	let timeout_promise = new Promise(function (resolve, reject) {
		timeout_fn = function () {
			reject('请求超时!!!');
		};
	});
	let abortable_promise = Promise.race([fetch_promise, timeout_promise]);
	setTimeout(function () {
		timeout_fn();
	}, timeout);

	return abortable_promise;
}


/**
 * @param { string } url
 * @param { string } method
 * @param { JSON } params
 * @param { Boolean } removeToken
 * @returns
 *
 */
export const fetchRequest = (url , method , params = '' , Apiversion = '', removeToken = false)=>{
	let header;
	if(!url.includes('cms')){
		header = {
			'Content-Type': 'application/json; charset=utf-8',
			'Culture': 'zh-cn'
		};
	}
	return new Promise(function(resolve , reject){
		timeout_fetch(fetch(url + Apiversion , {
			method : method,
			headers : header,
			body: !params ? null : JSON.stringify(params)

		})).then((response) => response.json())
		   .then((data) =>{
				if(data.error_details){
					if(data.error_details.Code === "GEN0002"){
						Router.push('/restrict');
						return;
					}
				}

			   	resolve(data);
		   }).then((error)=>{

				reject(error);
		   })
	})
}


